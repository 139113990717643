import React, { useState, useEffect } from 'react';

const ImageSection = () => {
  const imgCount = 1984;
  const [randomImages, setRandomImages] = useState({ img1: '', img2: '', img3: '', img4: '' });

  const generateRandomImages = () => {
    const randomIndices = [];
    for (let i = 0; i < 4; i++) {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * imgCount);
      } while (randomIndices.includes(randomIndex));
      randomIndices.push(randomIndex);
    }

    setRandomImages({
      img1: `https://arweave.net/NZ4oRsWSwdO0d6X6uf62phxbbMmwf9mzV3xHYO5dDBA/${randomIndices[0]}.png`,
      img2: `https://arweave.net/NZ4oRsWSwdO0d6X6uf62phxbbMmwf9mzV3xHYO5dDBA/${randomIndices[1]}.png`,
      img3: `https://arweave.net/NZ4oRsWSwdO0d6X6uf62phxbbMmwf9mzV3xHYO5dDBA/${randomIndices[2]}.png`,
      img4: `https://arweave.net/NZ4oRsWSwdO0d6X6uf62phxbbMmwf9mzV3xHYO5dDBA/${randomIndices[3]}.png`,
    });
  };

  useEffect(() => {
    generateRandomImages();
    const intervalId = setInterval(generateRandomImages, 3000);
    return () => clearInterval(intervalId);
  }, []);

  if (Object.values(randomImages).includes('')) {
    return <p>Loading...</p>;
  }

  return (
    <section className="h-full bg-gray-900 bg-opacity-0 overflow-hidden">
      <div className="max-w-screen-xl 2xl:max-w-screen-3xl px-8 md:px-12 mx-auto py-12 lg:py-24 space-y-12 h-full flex flex-col justify-center">
        <div className="flex flex-col sm:flex-row mx-auto">
          <a href="#_" className="relative flex flex-col items-center">
            <img
              src={randomImages.img1}
              className="rounded-xl rotate-12 hover:rotate-0 duration-500 h-full w-full object-cover hover:scale-150 transform origin-center"
              alt={`Edition #${randomImages.img1.match(/(\d+)\.png/)[1]}`}
            />
            <div className="absolute bottom-4 left-4 text-white text-lg bg-black bg-opacity-50 px-2 py-1 rounded">
              Edition #{randomImages.img1.match(/(\d+)\.png/)[1]}
            </div>
          </a>
          <a href="#_" className="relative flex flex-col items-center">
            <img
              src={randomImages.img2}
              className="rounded-xl -rotate-12 hover:rotate-0 duration-500 h-full w-full object-cover hover:scale-150 transform origin-center"
              alt={`Edition #${randomImages.img2.match(/(\d+)\.png/)[1]}`}
            />
            <div className="absolute bottom-4 left-4 text-white text-lg bg-black bg-opacity-50 px-2 py-1 rounded">
              Edition #{randomImages.img2.match(/(\d+)\.png/)[1]}
            </div>
          </a>
          <a href="#_" className="relative flex flex-col items-center">
            <img
              src={randomImages.img3}
              className="rounded-xl rotate-2 hover:rotate-0 duration-500 h-full w-full object-cover hover:scale-150 transform origin-center"
              alt={`Edition #${randomImages.img3.match(/(\d+)\.png/)[1]}`}
            />
            <div className="absolute bottom-4 left-4 text-white text-lg bg-black bg-opacity-50 px-2 py-1 rounded">
              Edition #{randomImages.img3.match(/(\d+)\.png/)[1]}
            </div>
          </a>
          <a href="#_" className="relative flex flex-col items-center">
            <img
              src={randomImages.img4}
              className="rounded-xl -rotate-12 hover:rotate-0 duration-500 h-full w-full object-cover hover:scale-150 transform origin-center"
              alt={`Edition #${randomImages.img4.match(/(\d+)\.png/)[1]}`}
            />
            <div className="absolute bottom-4 left-4 text-white text-lg bg-black bg-opacity-50 px-2 py-1 rounded">
              Edition #{randomImages.img4.match(/(\d+)\.png/)[1]}
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
