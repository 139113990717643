import React from 'react';
import MatrixRain from './MatrixRain';

const AboutSection = () => {
  return (
    <div className="w-full p-6 text-white">
      <div className="flex flex-col md:flex-row items-center justify-center">

        <div className="flex-child md:w-1/3 p-4">
          <img src="./images/Treasure.png" className="w-full h-auto" alt="Treasure Hunt" />
        </div>

        <div className="flex-child md:w-1/2 p-4 bg-pink-400 p-4 bg-opacity-90 rounded-xl max-w-lg">
          <h1 className="text-3xl font-bold mb-4">TreeaSsure hUnt</h1>
          <p className="text-2xl mb-4">
          Deep within the DumDum vault is a hidden treasure just waiting to be found. Do you have what it takes to go down the rabbit hole and piece together the clues? 
          </p>
        </div>
        
      </div>
    </div>
  );
};

export default AboutSection;
