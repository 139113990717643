import React from 'react';
import { Link } from 'react-router-dom';
import { CiSearch, CiHome } from "react-icons/ci";

const Navbar = () => {
  return (
    <nav className="bg-pink-500 p-4 bg-opacity-90 rounded-b-xl mx-auto inline-block">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <ul className="flex space-x-6">
            
            <li className="flex flex-col items-center">
              <Link to="/" className="text-white hover:text-gray-400 flex flex-col items-center">
                <CiHome className="w-6 h-6 mb-1" />
                Home
              </Link>
            </li>

            {/* <li className="flex flex-col items-center">
              <Link to="/whitelist" className="text-white hover:text-gray-400 flex flex-col items-center">
                <CiHome className="w-6 h-6 mb-1" />
                Whitelist
              </Link>
            </li> */}

            <li className="flex flex-col items-center">
              <Link to="/search" className="text-white hover:text-gray-400 flex flex-col items-center">
                <CiSearch className="w-6 h-6 mb-1" /> 
                Search
              </Link>
            </li>

            <li className="flex flex-col items-center">
              <Link to="https://ao-bazar.arweave.dev/#/collection/JAHF1fo4MECRZZFKGcT0B6XM94Lqe-3FtB4Ht_kTEK0" className="text-white hover:text-gray-400 flex flex-col items-center">
                <img src="./images/BazAR Logo.svg" alt="Home Icon" className="w-6 h-6 mb-1" />
                Buy
              </Link>
            </li>

            <li className="flex flex-col items-center">
              <Link to="https://discord.gg/p6WMtXJF" className="text-white hover:text-gray-400 flex flex-col items-center">
                <img src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/653714c1f22aef3b6921d63d_636e0a6ca814282eca7172c6_icon_clyde_white_RGB.svg" alt="Frenz Icon" className="w-6 h-6 mb-1" />
                Frenz
              </Link>
            </li>

            <li className="flex flex-col items-center">
              <a href="https://x.com/dumdumznfts" className="text-white hover:text-gray-400 flex flex-col items-center">
                <img src="./images/x_logo.svg" alt="x Icon" className="w-6 h-6 mb-1" />
                Yap
              </a>
            </li>

            <li className="flex flex-col items-center">
              <a href="https://ao.arweave.dev/" className="text-white hover:text-gray-400 flex flex-col items-center">
                <img src="./images/AO Logo.svg" alt="AO Icon" className="w-6 h-6 mb-1" />
                Neato
              </a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
