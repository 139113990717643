import React from 'react';

const FullScreenNFT = ({ nft, onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
      onClick={handleOverlayClick}
    >
      <div className="relative bg-pink-500 rounded-lg shadow-lg p-4 max-w-3xl w-full" onClick={handleOverlayClick}>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-black bg-gray-200 rounded-full p-2 hover:bg-gray-300"
        >
          Close
        </button>
        <div className="flex flex-col md:flex-row" onClick={handleOverlayClick}>
          <div className="relative flex-shrink-0 md:w-1/2" onClick={handleOverlayClick}>
            <img src={nft.imageUrl} alt={nft.name} className="rounded-lg w-full h-auto" onClick={handleOverlayClick} />
            <span className="absolute bottom-4 left-2 text-xs text-white bg-black bg-opacity-50 px-2 py-1 rounded">
              Click to close
            </span>
          </div>
          <div className="mt-4 md:mt-0 md:w-1/2 md:ml-4" onClick={handleOverlayClick}>
            <h2 className="text-2xl font-bold mb-2">{nft.name}</h2>
            <div>
              <ul>
                {nft.attributes.map((attr, index) => (
                  <li key={index} className="mb-2">
                    <span className="font-bold">{attr.trait_type}:</span> {attr.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenNFT;
