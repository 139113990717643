import React, { useState } from 'react';

const TeamSection = () => {
  const [hovered, setHovered] = useState(null);

  const profiles = [
    {
      id: 1,
      defaultImage: "./images/profile/Crypto_Cherie_nft.png",
      hoverImage: "./images/profile/Crypto_Cherie_Profile.jpg",
      role: "Founder",
      name: "Crypto Cherie",
      x: "https://twitter.com/Crypto_Cherie",
    },
    {
      id: 2,
      defaultImage: "./images/profile/FUDBear_nft.png",
      hoverImage: "./images/profile/FUDBear_Profile.jpg",
      role: "Builder",
      name: "FUDBear",
      x: "https://twitter.com/Josh_Burleson",
    },
    {
      id: 3,
      defaultImage: "./images/profile/Jonny_Ringo_nft.png",
      hoverImage: "./images/profile/Jonny_Ringo_Profile.jpg",
      role: "Advisor",
      name: "Jonny Ringo",
      x: "https://x.com/JonnyRingo711",
    }
  ];

  return (
    <div className="section3 p-12 bg-blue-500 bg-opacity-90 rounded-xl text-white">

      <h1 className="text-3xl font-bold mb-4">Team</h1>

      <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-8">
        {profiles.map(profile => (

          <div
            key={profile.id}
            className="flex-child w-32 h-32 transition-transform transform hover:scale-110"
            onMouseEnter={() => setHovered(profile.id)}
            onMouseLeave={() => setHovered(null)}
          >
          
          <a href={profile.x} className="text-white flex flex-col items-center">
            
            <img
              src={hovered === profile.id ? profile.hoverImage : profile.defaultImage}
              className="rounded-full w-full h-full object-cover"
              alt={`Profile ${profile.id}`}
            />

            <p className="text-lg mb-4">
              {hovered === profile.id ? profile.name : profile.role}
            </p>

          </a>

          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
