import React from 'react';
import { Link } from 'react-router-dom';

const DescriptionSection = () => {
  return (
    <div className="section3 p-6 text-white">
      <div className="flex flex-col md:flex-row items-center justify-center">

        <div className="flex-child md:w-1/2 p-4 bg-pink-400 bg-opacity-90 rounded-xl max-w-lg">
          <h1 className="sectionTitle text-3xl font-bold mb-4">We R DumDumz</h1>
          <p className="sectionBaseText text-2xl mb-4">
          Feeling like an edgelord? Then maybe a 1984 shirt with some synth glasses is your style, or perhaps get fancy with a top hat and a gold chain? Whatever vibe you’re going for you can use the DumDumz attribute search tool to find your perfect DumDum. 
          </p>
          <Link to="/search">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-3xl py-2 px-4 rounded">
              Open NFT Search Tool
            </button>
          </Link>
        </div>

        <div className="flex-child md:w-1/3 p-4">
          <img src="./images/0.png" className="w-full h-auto" alt="DumDum riding a lama" />
        </div>

      </div>
    </div>
  );
};

export default DescriptionSection;
