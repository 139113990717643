import React from 'react';
import { Link } from 'react-router-dom';
import {
  Rive,
  useRive,
  useStateMachineInput,
  Fit,
  Alignment,
  EventType,
  RiveEventType
} from "@rive-app/react-canvas";

const HeroSection = () => {

  const anim = useRive({
    rive: 'main',
    src: '../images/dumdumz_banner.riv',
    stateMachines: 'State Machine 1',
    autoplay: true,
    artboard: "main_artboard",
    onLoad: () => console.log('Rive loaded'),
    onUnmount: () => console.log('Rive component will unmount'),
    
});

  return (
    <div className="section3 p-6 text-white rounded-lg">
      <div className="flex flex-col md:flex-row items-center justify-center">

        <div className="flex-child p-4">
          {/* <img src="./images/WelcomeLarge.png" className="w-full h-auto" alt="Welcome Sign Image" /> */}
          <anim.RiveComponent style={{
                width: '320px',
                height: '320px',
              }} />
        </div>

        <div className="flex-child md:w-1/2 p-4 bg-pink-400 p-4 bg-opacity-90 rounded-xl max-w-lg">
          <h1 className="sectionTitle text-3xl font-bold mb-4">wAt diS?</h1>
          <p className="sectionBaseText text-2xl mb-4">
          Welcome to DumDumz.xyz, your home for the #1984 piece NFT collection exclusive to the AO blockchain. You can adopt your very own DumDum on the bazaAR NFT platform.
          </p>

          <Link to="https://ao-bazar.arweave.dev/#/collection/JAHF1fo4MECRZZFKGcT0B6XM94Lqe-3FtB4Ht_kTEK0">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-3xl py-2 px-4 rounded">
              Adopt A DumDum
            </button>
          </Link>
          
        </div>
        
      </div>
    </div>
  );
};

export default HeroSection;
