import React from 'react';

const Terms = () => {
  return (
    <div className="max-w-2xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p>
        Welcome to our website. By accessing and using our services, you agree to comply with the following Terms of Service. If you do not agree with any part of these terms, please do not use our website.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Acceptance of Terms</h2>
      <p>
        By using our services, you agree to be bound by these Terms of Service and our Privacy Policy. We reserve the right to update or modify these terms at any time without prior notice. Your continued use of the service after any changes constitutes your acceptance of the new terms.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Use of Personal Information</h2>
      <p>
        By providing us with your email address and any other personal information, you grant us permission to use this information for any purpose, including marketing and communication. You acknowledge that we may share your information with third parties as described in our Privacy Policy.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">3. Intellectual Property</h2>
      <p>
        All content provided on this website is owned by or licensed to us and is protected by intellectual property laws. You may not reproduce, distribute, or use any content without our express written permission.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Limitation of Liability</h2>
      <p>
        We are not liable for any damages arising from your use of our services. Our total liability to you for any claim arising out of or relating to these terms is limited to the amount you paid for the services.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with the laws of the jurisdiction in which our company is established.
      </p>

      <h1 className="text-3xl font-bold mt-10 mb-6">Privacy Policy</h1>
      <p>
        Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Information We Collect</h2>
      <p>
        We collect personal information that you provide to us, such as your email address, wallet information, and any other information you choose to provide. We may also collect data automatically through cookies and similar technologies.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Use of Information</h2>
      <p>
        We use your information to provide and improve our services, communicate with you, and for marketing purposes. We may share your information with third-party service providers who assist us in our operations.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">3. Data Security</h2>
      <p>
        We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Changes to This Policy</h2>
      <p>
        We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any significant changes by posting the new policy on our website.
      </p>
      <p className="mt-6">
        If you have any questions or concerns about our Terms of Service or Privacy Policy, please contact us at dumdumz@gmail.com.
      </p>
    </div>
  );
};

export default Terms;
