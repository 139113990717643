import React from 'react';
import './AnimatedBackground.css';

const AnimatedBackground = () => {
  const colors = [
    "#3b82f6",
    "#a855f7",
    "#6366f1",
    "#ec4899",
    "#84cc16",
    "#f59e0b",
    "#ef4444",
    "#14b8a6",
    "#bef264",
    "#6d28d9"
  ];

  const imgBaseUrl = 'https://arweave.net/NZ4oRsWSwdO0d6X6uf62phxbbMmwf9mzV3xHYO5dDBA/';
  const imgCount = 1984;

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imgCount);
    return `${imgBaseUrl}${randomIndex}.png`;
  };

  const getRandomZIndex = () => {
    return Math.floor(Math.random() * 10);
  };

  const getRandomSize = () => {
    return Math.floor(Math.random() * 100) + 20; // Random size between 20px and 120px
  };

  const getRandomBackground = () => {
    if (Math.random() < 1 / 3) {
      return {
        backgroundImage: `url(${getRandomImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
      };
    } else {
      return {
        backgroundColor: getRandomColor(),
        backgroundImage: 'none',
      };
    }
  };

  const movingCircles = Array.from({ length: 20 }, (_, index) => {
    const backgroundStyle = getRandomBackground();
    const size = getRandomSize();
    return (
      <li
        key={`moving-${index}`}
        className="moving-circle"
        style={{
          ...backgroundStyle,
          animationDelay: `${index * 2}s`,
          animationDuration: `${Math.random() * 20 + 10}s`,
          zIndex: getRandomZIndex(),
          width: `${size}px`,
          height: `${size}px`,
          left: `${Math.random() * 100}%`,
          borderRadius: '50%',
        }}
      />
    );
  });

  const staticCircles = Array.from({ length: 10 }, (_, index) => {
    const backgroundStyle = getRandomBackground();
    const size = getRandomSize();
    return (
      <li
        key={`static-${index}`}
        className="static-circle"
        style={{
          ...backgroundStyle,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          zIndex: getRandomZIndex(),
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
        }}
      />
    );
  });

  return (
    <div className="area">
      <ul className="circles">
        {staticCircles}
        {movingCircles}
      </ul>
    </div>
  );
};

export default AnimatedBackground;
