import React from 'react';
import { Link } from 'react-router-dom';
import HeroSection from './HeroSection';
import DescriptionSection from './DescriptionSection';
import AboutSection from './AboutSection';
import ImageSection from './ImageSection';
import TeamSection from './TeamSection';

function Home() {
  return (
    <div>
      <div className="background-fixed min-h-screen">
        <header className="text-white min-h-screen flex flex-col items-center justify-center">
          <HeroSection />
          <br />
          <ImageSection />
          <br />

          <DescriptionSection />
          <br />

          <TeamSection />
          <br />
          
          <AboutSection />
        </header>
      </div>
    </div>
  );
}

export default Home;
