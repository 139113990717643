import React, { useState, useEffect } from "react";
import { functions } from '../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
import { connect, dryrun } from "@permaweb/aoconnect";
import PulseLoader from 'react-spinners/PulseLoader';

const Dumdumz = "JAHF1fo4MECRZZFKGcT0B6XM94Lqe-3FtB4Ht_kTEK0";
const wAR = "xU9zFkq3X2ZQ6olwNVvr1vUWIjc3kXTWr7xKQD6dh10";

const Testing = () => {

    const [discord, setDiscord] = useState("");
    const [email, setEmail] = useState("");
    const [wallet, setWallet] = useState("");
    const [profile, setProfile] = useState("");
    const [agreed, setAgreed] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [balance , setBalance] = useState(0);
    const [hasBalance, setHasBalance] = useState(false);

    const GetwARBalance = async (address) => { 
        if (address) {
          console.log("Fetching balance for: " + address);
          try {
            const messageResponse = await dryrun({
              process: wAR,
              tags: [
                { name: 'Action', value: 'Balance' },
                { name: 'Recipient', value: address },
              ],
            });
      
            const balanceTag = messageResponse.Messages[0].Tags.find((tag) => tag.name === 'Balance');
            const balance = balanceTag ? parseFloat((balanceTag.value / 1000).toFixed(4)) : 0;
            console.log("Balance: ", balance);
            return balance;
      
          } catch (error) {
            console.error(error);
            return 0;
          }
        } else {
          return 0;
        }
      };
    
    
      const getwARBalance = async () => {
    
        if( !wallet ) {
          console.log("Wallet not connected!");
          return;
        }
    
        setBalance( await GetwARBalance( wallet ) );
      };
    
      useEffect(() => {
        
        // This is a gross way to do this bro
        const formattedBalance = GetFormattedBalance(balance);
        const minimumRequiredBalance = 0.1984;
        if (parseFloat(formattedBalance) >= minimumRequiredBalance) {
          console.log("Sufficient balance:", formattedBalance);
          setHasBalance(true);
        } else {
          console.log("Insufficient balance:", formattedBalance);
          setHasBalance(false);
        }
      }, [balance]);

      const getActiveAddress = async () => {
        try {
          const address = await window.arweaveWallet.getActiveAddress();
          return address;
        } catch (error) {
          console.error("Failed to get active address:", error);
        }
      }

      const handleArweaveWalletLoaded = async () => {
        try {
          const permissions = await window.arweaveWallet.getPermissions();
          if (permissions.length <= 0) {
            await window.arweaveWallet.connect(["ACCESS_ADDRESS"]);
            console.log("Connected to ArConnect and obtained permissions.");
          } else {
            console.log("Already have permissions:", permissions);
          }
    
          setWallet( await getActiveAddress() );
    
        } catch (error) {
          console.error("Error during ArConnect initialization:", error);
        }
      };

      const GetFormattedBalance = (balance) => {
        return (balance / 1e9).toFixed(4); 
      };

  
    return (
        <div className="max-w-lg mx-auto bg-white p-8 shadow-lg rounded-lg">
            <div className="mb-4 border border-gray-300 rounded-lg p-4">

                <div className="mb-4">
                    <label className="text-xl block text-gray-700">AO Wallet</label>

                    { wallet ? 
                        ( <label className="text-l block text-gray-400">{wallet}</label> )
                        : 
                        (
                        <button type="button" onClick={handleArweaveWalletLoaded} className="w-full bg-blue-500 text-white py-2 rounded mt-2 hover:bg-blue-700 transition duration-300" >
                        Connect Wallet
                        </button>
                        )
                    }     
                    
                </div>

                {wallet && 
                    <div className="mb-4 flex items-center justify-center">
                        <img src="./images/wAR_Logo.png" alt="wAR Logo" className="w-12 h-auto rounded-full" />
                        <div className="flex flex-col justify-center items-center ml-4">
                            <label className="text-md block text-gray-700">{GetFormattedBalance(balance)}</label>
                            {hasBalance ? (
                            <label className="text-sm block text-green-500">Sufficient Balance</label>
                            ) : (
                            <label className="text-sm block text-red-500">Insufficient Balance</label>
                            )}
                        </div>
                    </div>
                }

          </div>
        </div>
    );
  };
  
  export default Testing;