import React, {useEffect} from 'react';
import './App.css';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './component/Navbar';
import Home from './component/Home';
import AnimatedBackground from './AnimatedBackground';
import NFTAttributeSearch from './component/NFTAttributeSearch';
import Whitelist from './component/Whitelist';
import Terms from './component/Terms';
import Testing from './component/Testing';

function App() {

  // useEffect(() => {
  //   const handleArweaveWalletLoaded = async () => {
  //     try {
  //       const permissions = await window.arweaveWallet.getPermissions();
  //       if (permissions.length <= 0) {
  //         await window.arweaveWallet.connect(["ACCESS_ADDRESS"]);
  //         console.log("Connected to ArConnect and obtained permissions.");
  //       } else {
  //         console.log("Already have permissions:", permissions);
  //       }
  //     } catch (error) {
  //       console.error("Error during ArConnect initialization:", error);
  //     }
  //   };

  //   window.addEventListener("arweaveWalletLoaded", handleArweaveWalletLoaded);

  //   return () => {
  //     window.removeEventListener("arweaveWalletLoaded", handleArweaveWalletLoaded);
  //   };
  // }, []);

  return (
    <div className="App">
      <Router>
      <AnimatedBackground />
      <Navbar />
      <div className="background-fixed min-h-screen">
        <header className="text-white min-h-screen flex flex-col items-center justify-center">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<NFTAttributeSearch />} />
            {/* <Route path="/whitelist" element={<Whitelist />} /> */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/testing" element={<Testing />} />
          </Routes>
        </header>
      </div>
    </Router>
    </div>
  );
}

export default App;
