import React, { useState, useEffect } from 'react';
import FullScreenNFT from './FullScreenNFT';

const NFTAttributeSearch = () => {
  const [nfts, setNfts] = useState([]);
  const [traitTypes, setTraitTypes] = useState({});
  const [selectedTraitValues, setSelectedTraitValues] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNft, setSelectedNft] = useState(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/json/_metadata.json`);
        if (response.ok) {
          const data = await response.json();
          setNfts(data);
          extractTraitTypes(data);
        } else {
          console.error('Failed to fetch metadata:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, []);

  const extractTraitTypes = (data) => {
    const allTraitTypes = {};
    data.forEach(nft => {
      nft.attributes.forEach(attr => {
        if (!allTraitTypes[attr.trait_type]) {
          allTraitTypes[attr.trait_type] = new Set();
        }
        allTraitTypes[attr.trait_type].add(attr.value);
      });
    });
    Object.keys(allTraitTypes).forEach(key => {
      allTraitTypes[key] = Array.from(allTraitTypes[key]);
    });
    setTraitTypes(allTraitTypes);
  };

  const handleTraitValueChange = (e, traitType) => {
    const value = e.target.value;
    setSelectedTraitValues(prevValues => {
      const existingTrait = prevValues.find(val => val.traitType === traitType);
      if (existingTrait) {
        if (existingTrait.value === value) {
          return prevValues.filter(val => val.traitType !== traitType);
        } else {
          return prevValues.map(val => val.traitType === traitType ? { traitType, value } : val);
        }
      } else {
        return [...prevValues, { traitType, value }];
      }
    });
  };

  const fetchImageUrl = (edition) => {
    return `https://arweave.net/NZ4oRsWSwdO0d6X6uf62phxbbMmwf9mzV3xHYO5dDBA/${edition}.png`;
  };

  const filterNfts = () => {
    const filtered = nfts.filter(nft =>
      selectedTraitValues.every(({ traitType, value }) =>
        nft.attributes.some(attr => attr.trait_type === traitType && attr.value === value)
      )
    );

    const nftsWithImages = filtered.map(nft => ({
      ...nft,
      imageUrl: fetchImageUrl(nft.edition),
    }));

    setFilteredNfts(nftsWithImages);
  };

  useEffect(() => {
    if (selectedTraitValues.length > 0) {
      filterNfts();
    } else {
      setFilteredNfts([]);
    }
  }, [selectedTraitValues]);

  const handleResetFilters = () => {
    setSelectedTraitValues([]);
    document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.checked = false;
    });
  };

  return (
    <div className="flex">
      {selectedNft && <FullScreenNFT nft={selectedNft} onClose={() => setSelectedNft(null)} />}
      <div className={`fixed inset-0 bg-black bg-opacity-50 z-40 ${drawerOpen ? 'block' : 'hidden'}`} onClick={() => setDrawerOpen(false)}></div>
      <div className={`fixed inset-y-0 left-0 w-64 bg-pink-500 shadow-md z-50 transform ${drawerOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out overflow-y-auto`}>
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">Search NFTs by Trait Value</h2>
          <button 
            onClick={handleResetFilters} 
            className="mb-4 p-2 bg-gray-200 rounded-full text-black hover:bg-gray-300 transition-transform duration-150 ease-in-out transform active:scale-95"
          >
            Reset Filters
          </button>
          <div className="mb-4">
            {Object.keys(traitTypes).map(traitType => (
              <details key={traitType} className="mb-2">
                <summary className="cursor-pointer">{traitType} ({traitTypes[traitType].length})</summary>
                <div className="ml-4 mt-2">
                  {traitTypes[traitType].map(value => (
                    <label key={value} className="inline-flex items-center mr-2 mb-2">
                      <input
                        type="checkbox"
                        value={value}
                        onChange={(e) => handleTraitValueChange(e, traitType)}
                        className="form-checkbox"
                      />
                      <span className="ml-2">{value}</span>
                    </label>
                  ))}
                </div>
              </details>
            ))}
          </div>
        </div>
      </div>
      {!drawerOpen && (
        <button 
          onClick={() => setDrawerOpen(true)} 
          className="p-6 bg-gray-200 text-black fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 text-2xl rounded-full w-48 h-16 flex items-center justify-center shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
        >
          Open Filter
        </button>
      )}
      <div className="flex-1 p-4">
        {selectedTraitValues.length > 0 && (
          <div>
            <h3 className="text-xl font-semibold mb-4">
              Results for trait values: {selectedTraitValues.map(({ value }) => value).join(', ')} ({filteredNfts.length} found)
            </h3>
            {filteredNfts.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {filteredNfts.map(nft => (
                  <div 
                    key={nft.edition} 
                    className="relative bg-pink-400 rounded-lg shadow-lg max-w-xs w-full mx-auto cursor-pointer"
                    onClick={() => setSelectedNft(nft)}
                  >
                    <div className="relative pt-10 px-10 flex items-center justify-center">
                      <div
                        className="absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                        style={{
                          background: 'radial-gradient(black, transparent 60%)',
                          transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)',
                          opacity: 0.2,
                        }}
                      ></div>
                      {nft.imageUrl && (
                        <img
                          className="relative w-40 rounded-md shadow-lg"
                          src={nft.imageUrl}
                          alt={nft.name}
                        />
                      )}
                    </div>
                    <div className="relative text-white px-6 pb-6 mt-6">
                      <div className="flex justify-center">
                        <span className="block font-semibold text-xl">
                          {nft.name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No NFTs found for these trait values.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTAttributeSearch;
